import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import SEO from "../components/seo"

const BackToBusinessPage = ({ data, pageContext }) => {
  const post = data.allUniquePagesJson.nodes[0]

  const iconColor = post.iconColor || "002d73"

  return (
    <Layout brand={pageContext.brand}>
      <SEO title={post.metaTitle} description={post.metaDescription} />
      <div className="joshua-tree-content">
        <div className="b2b-banner">
          <h1>{post.heading}</h1>
        </div>

        <div className="b2b-top-section">
          <div className="columns">
            <div className="column is-5"></div>
            <div className="column">
              {post.subHeadingBlurb && (
                <p className="top-p"> {post.subHeadingBlurb} </p>
              )}
            </div>
            <div className="column is-5"></div>
          </div>

          <div className="treatment-grid">
            <div className="columns">
              <div className="column is-6"></div>

              <div className="treatment-column column">
                <div className="icon-wrapper">
                  <img
                    src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,co_rgb:${iconColor},e_colorize:100,f_png/v1/Programs/doors-icon-blue`}
                  />
                </div>
                <MarkdownViewer markdown={post.iconOneHeading} />
                <p> {post.iconOneBlurb} </p>
              </div>
              <div className="column is-1"></div>

              <div className="treatment-column column">
                <div className="icon-wrapper">
                  <img
                    src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,co_rgb:${iconColor},e_colorize:100,f_png/v1/Programs/safety-icon-blue`}
                  />
                </div>
                <MarkdownViewer markdown={post.iconTwoHeading} />
                <p> {post.iconTwoBlurb} </p>
              </div>

              <div className="column is-6"></div>
            </div>
          </div>
        </div>

        <section className="body-section b2b-body">
          <div className="b2b-body wrapper columns">
            <div className="column is-3"></div>

            <div className="column">
              <MarkdownViewer markdown={post.text} />
            </div>
            <div className="column is-3"></div>
          </div>
        </section>

        <div className="b2b-cta wrapper columns color-back has-text-centered">
          <div className="column is-5"></div>
          <div className="column">
            <h3> {post.scheduleAppointment.heading} </h3>
            <p>{post.scheduleAppointment.blurb}</p>
            <ButtonGroupMap
              isCentered
              buttons={post.scheduleAppointment.buttons}
            />
          </div>

          <div className="column is-5"></div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query OpenForCare {
    allUniquePagesJson(filter: { title: { eq: "open-for-care/" } }) {
      nodes {
        heading
        metaDescription
        metaTitle
        searchHeading
        heading
        subHeadingBlurb
        iconColor
        iconOneHeading
        iconOneBlurb
        iconTwoHeading
        iconTwoBlurb
        text
        scheduleAppointment {
          blurb
          buttons {
            button {
              appearance
              buttonText
              destination
              href
            }
          }
          heading
        }
      }
    }
  }
`

export default BackToBusinessPage
